<template>
  <div v-if="settings">
    <div
      class="search container bg-grey action-viewer"
      style="
        z-index: 9999;
        position: absolute;
        top: -10px;
        display: inline-table;
        height: 70px;
        left: 0px;
      "
      v-show="settings.expand"
    >
      <div class="search__caption">
        <div class="search__caption__copy">
          <!-- viene mostrato solo in versione smartphone -->
          <div
            class="filter results__actions ridimensiona"
            style="float: left; margin-left: 0px; padding-left: 40px"
          >
            <span
              class="lnk blue"
              style="cursor: pointer; margin-top: -8px"
              @click="expandViewer(false)"
              title="Ridimensiona"
            >
              <span class="text" style="font-weight: 700 !important"
                ><v-icon>fullscreen_exit</v-icon> Ridimensiona</span
              >
            </span>
          </div>
        </div>
        <span
          v-if="showTenPercent"
          class="expand"
          style="
            position: absolute;
            font-weight: 700 !important;
            cursor: default !important;
            text-decoration: none;
            top: 20px;
            left: 0;
            margin: 0 auto;
            width: 100%;
            z-index: 9999;
            text-align: center;
          "
        >
          Visualizzazione limitata al 10% delle pagine dell'articolo
        </span>
        <span
          v-else-if="rivista"
          class="expand"
          style="
            position: absolute;
            font-weight: 700 !important;
            cursor: default !important;
            text-decoration: none;
            top: 20px;
            left: 0;
            margin: 0 auto;
            width: 100%;
            z-index: 9999;
            text-align: center;
            font-size: 16pt;
          "
        >
          {{ rivista.titolo }}, {{ rivista.anno }}, {{ rivista.fascicolo }}
        </span>
      </div>
    </div>

    <span
      class="expand"
      style="
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 10px;
        z-index: 99;
      "
      @click="expandViewer(true)"
      title="Espandi a tutto schermo"
    >
      <span
        class="text"
        style="
          font-size: 13px;
          line-height: 16px;
          cursor: pointer;
          font-weight: 700 !important;
        "
        ><v-icon>fullscreen</v-icon> ESPANDI</span
      >
    </span>
    <span
      v-if="showTenPercent"
      class="expand"
      style="
        position: absolute;
        font-weight: 700 !important;
        cursor: default !important;
        text-decoration: none;
        top: 25px;
        left: 0;
        z-index: 10;
        margin: 0 auto;
        width: 100%;
        text-align: center;
      "
    >
      Visualizzazione limitata al 10% delle pagine dell'articolo
    </span>

    <span
      v-else-if="rivista && label == ''"
      class="expand label-viewer"
    >
      {{ rivista.titolo }}, {{ rivista.anno }}, {{ rivista.fascicolo }}
    </span>

    <span v-else-if="label != ''" class="expand label-viewer"> 
      {{label}}
    </span>

    <iframe
      id="ifr"
      width="102%"
      :style="[
        {
          height: !currentStatus ? 'calc(100vh - 190px)' : 'calc(100vh - 45px)',
        },
      ]"
      :class="[{ 'full-page': settings.expand }]"
      frameborder="0"
      scrolling="no"
      :src="settings.viewerUrl"
    ></iframe>
  </div>
</template>

<style>
.full-page {
  position: absolute;
  height: 100vh;
  top: -130px;
  z-index: 999999;
  left: 0;
}
</style>
<script>
import NoContentViewVue from "../components/catalogue/NoContentView.vue";
import axios from "axios";

export default {
  props: {
    authorization: {
      type: Object,
    },
    fromResult: {
      type: Boolean,
    },
    settings: {
      type: Object,
    },
    rivista: {
      type: Object,
    },
    label: {
      type: String,
    },
  },
  components: {
    NoContentView: NoContentViewVue,
  },
  data: (vm) => ({
    showTenPercent: false,
    currentStatus: false,
  }),
  created() {
    if (this.fromResult) {
      document.getElementsByClassName("maxlayout")[0].style.maxWidth = "none";
    }
  },
  mounted() {
    var self = this;
    window.onmessage = function (e) {
      if (e.data == "locked") {
        self.showTenPercent = true;
        self.$forceUpdate();
      }
      if (e.data == "unlocked") {
        self.showTenPercent = false;
        self.$forceUpdate();
      }
    };
    if (this.fromResult) {
      document.getElementById("ifr").style.marginTop = "45px";
    }
  },
  methods: {
    expandViewer(status) {
      this.currentStatus = status;
      if (status) {
        if (this.fromResult) {
          document.getElementById("ifr").style.marginTop = "75px";
        }
        document.getElementsByClassName("expandContainer")[0].style.position =
          "static";
        document.getElementById("gfl-hf-plain").style.display =
          "none";
        document.getElementsByClassName("maxlayout")[0].style.maxWidth = "none";
        document.getElementsByTagName("iframe")[0].style.position = "absolute";
        this.fromResult
          ? (document.getElementsByTagName("iframe")[0].style.top = "-190px")
          : (document.getElementsByTagName("iframe")[0].style.top = "-120px");

        document.getElementsByClassName("action-viewer")[1].style.left = "15px";
        document.getElementsByClassName("action-viewer")[1].style.top =
          "-150px";
        document.getElementsByClassName("action-viewer")[1].style.marginLeft =
          "0px";
        this.fromResult
          ? (document.getElementsByClassName("action-viewer")[1].style.height =
              "30px")
          : (document.getElementsByClassName("action-viewer")[1].style.height =
              "70px");

        if (window.screen.width < 768) {
          document.getElementsByTagName("iframe")[0].style.position =
            "absolute";
          document.getElementsByTagName("iframe")[0].style.top = "-140px";
          document.getElementsByClassName("action-viewer")[1].style.left =
            "15px";
          document.getElementsByClassName("action-viewer")[1].style.top =
            "-140px";
        }
      } else {
        if (this.fromResult) {
          document.getElementById("ifr").style.marginTop = "45px";
        }
        document.getElementsByClassName("expandContainer")[0].style.position =
          "relative";
        document.getElementById("gfl-hf-plain").style.display =
          "block";
        document.getElementsByTagName("iframe")[0].style.position = "relative";
        document.getElementsByTagName("iframe")[0].style.top = "0px";
      }
      this.settings.expand = status;
      this.$forceUpdate();
    },
  },
};
</script>

<style >
.label-viewer{
    position: absolute;
    font-weight: 700 !important;
    cursor:default !important;
    text-decoration:none;
    top: 20px;
    left: 0;
    margin: 0 auto;
    width: 100%;
    z-index:9999; 
    text-align:center; 
    font-size:16pt;
}
.detail .search.action-viewer .filter{
  margin-top: -30px;
}
.detail .search.action-viewer .filter span:hover{
 text-decoration: none;
}
</style>
