<template>
  <div>
    <!-- MAIN div contenitore da inserire nelle pagine Risultato di ricerca (class="main results-page") e Dettaglio volume (class="main detail") -->
    <div class="maxlayout container container__margin results-page">
      <!-- SEARCH sezione per contenere i campi di ricerca -->
      <div class="search bg-grey border-red ico-doc" v-show="!showViewer">
        <div class="search__caption">
          <div class="search__caption__copy">
            <div class="search__caption__copy__searchbox">
              <div class="azioni-top-right-azp">
                <div class="azioni-title-main-azp">
                  <div class="azioni-title-azp-ricerca">
                    <h1 class="hidden-md-and-up">IL CATALOGO</h1>
                  </div>
                  <div class="search-box-in-rip clearfix hole">
                    <div class="search-small-icon-hp">
                      <img src="images/ico_search.svg" alt="" />
                    </div>
                    <div class="search-small-right-hp">
                      <div class="search-small-input-hp" style="display: inline-flex;">
                        <v-select
                          class="filter__search__select"
                          name
                          @change="filtering()"
                          v-model="typeResearch"
                          :value="typeResearch"
                          :items="filtersTypeResearch"
                        >
                        </v-select>
                        <v-text-field
                          placeholder="Cerca per parole"
                          v-on:keyup="keyPress"
                          v-model="searchText"
                          filled
                          dense
                        ></v-text-field>
                        <v-select
                          class="filter__search__select"
                          name
                          v-model="filter_onwords"
                          :value="filter_onwords"
                          :items="allWordsSelect"
                        >
                        </v-select>
                        <div class="search-small-main-btn-hp">
                          <input
                            type="submit"
                            value="cerca"
                            @click="customSearch()"
                            class="cta red"
                          />
                          <div class="advance-hp">
                            <a
                              @click="showResAdv = !showResAdv"
                              v-if="!showResAdv"
                              class="lnk blue"
                              >Mostra filtri</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- pannello ricerca avanzata - va mostrato al click della CTA Ricerca Avanzata -->
      <div class="search__advanced" v-show="showResAdv && !showViewer">
        <div class="search__advanced__copy">
          <div class="search__advanced__copy__item">
            <div class="advanced__row no-gutters">
              <div class="flex advanced__row no-gutters col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label class="advanced__row__title">Data</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <label>Dal</label>
                  <v-text-field
                    label="Anno"
                    type="number"
                    v-model="dateFrom"
                    :rules="[rules.min]"
                    class="inp"
                  ></v-text-field>
                  <label>Al</label>
                  <v-text-field
                    label="Anno"
                    type="number"
                    v-model="dateTo"
                    :rules="[rules.max]"
                    class="inp"
                  ></v-text-field>
                </div>
              </div>
              <div class="flex advanced__row col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label class="advanced__row__title">Rivista</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <v-select
                    class="filter__search__select"
                    dense
                    name
                    v-model="filter_onriviste"
                    :value="filter_onriviste"
                    :items="rivisteSelect"
                    item-text="descrizione"
                    clearable="true"
                  >
                  </v-select>
                </div>
              </div>
              <div class="flex advanced__row col-sm-4 col-12">
                <div class="flex advanced__row col-sm-4 col-12">
                  <label class="advanced__row__title">Materia</label>
                </div>
                <div class="flex advanced__row col-sm-8 col-12">
                  <v-combobox
                    class="comboMateriaHome"
                    v-model="materiaSelected"
                    :items="materie"
                    light
                    item-text="descrizione"
                    dense
                    placeholder="Materia"
                    clearable
                    offset-y
                    @change="setMateria()"
                    :menu-props="{ auto: true }"
                  ></v-combobox>
                </div>
              </div>
            </div>
          </div>
          <div class="search__advanced__copy__actions">
            <a
              @click="
                showResAdv = !showResAdv;
                resetFields();
              "
              title="Annulla"
              class="cta red outline"
            >
              <span>Annulla</span>
            </a>
            <a
              title="Filtra"
              class="cta red"
              @click="customSearch()"
            >
              <span>Filtra</span>
            </a>
          </div>
        </div>
      </div>

      <div
        class="search container bg-grey action-viewer"
        style="z-index: 3; margin-top: -10px"
        v-show="showViewer"
      >
        <div class="search__caption">
          <div class="search__caption__copy">
            <!-- viene mostrato solo in versione smartphone -->
            <div
              class="filter results__actions"
              style="margin-top: -0px; left: 0; float: left"
            >
              <span
                class="lnk blue"
                style="cursor: pointer"
                @click="showViewer = false; fixcss()"
                title="Torna alla result list"
              >
                <span
                  class="mdi mdi-chevron-left"
                  style="font-size: 10pt; vertical-align: text-top"
                ></span>
                <span
                  class="text"
                  style="font-weight: 700 !important; font-size: 10pt"
                  >Torna alla result list</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="loading_viewer">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <section v-if="showViewer" class="reset-padding expandContainer">
        <Viewer :key="currentKey" :settings="settings" :fromResult="true"></Viewer>
      </section>
      <!-- RESULTS LIST -->
      <div class="results-list" v-show="!showViewer">
        <div class="results__list__pagination clearfix">
          <div class="col50 floatL">
            <span class="results-total">
              <span class="number">{{ totalResults }}</span
              ><span>risultati trovati</span>
            </span>
          </div>
          <div class="col50 floatR">
            <div class="pagination-number">
              <v-pagination
                v-model="page"
                v-if="totalResults > 8"
                :length="totalPages"
                :total-visible="8"
              ></v-pagination>
            </div>
          </div>
        </div>
        <div class="results-list__items">
          <div
            class="results-list__items__item clearfix"
            v-for="item in list"
            :key="item"
          >
            <div class="element">
              <div class="clearfix">
                <div class="col20 floatL element__image" @click="detail(item)">
                  <a
                    class="element__image__action"
                    title="Vai al dettaglio del volume"
                  >
                    <img
                      :src="generateCover(item)"
                      style="width: 217px; height: 100%"
                    />
                  </a>
                </div>
                <div class="col80 floatR element__caption">
                  <div class="element__caption__header">
                    <a class="title uppercase" @click="visite(item, 'set'); detail(item)"
                    :class="[
                          {
                            'title uppercase': true,
                            'title-visited': visite(item, 'check'),
                          },
                        ]"
                        style="cursor: pointer"
                        title="Vai al dettaglio della rivista"
                      ><span class="border-red"></span
                      >{{ item._source.provvedimento.eTitolo }}</a
                    >
                    <p style="width: 90%" v-show="false">
                      TODO: Questa rivista quadrimestrale affronta ed
                      approfondisce argomenti che si presentano determinanti
                      nella vita delle persone: dalla gestione del territorio,
                      alla cura dell’ambiente; dalla produzione di alimenti,
                      alla fornitura di servizi di...
                    </p>
                    <a
                      class="lnk red metadata__lnkmore"
                      @click="
                        item.showMoreMetadata = true;
                        $forceUpdate();
                      "
                      title="Leggi di più"
                      style="margin-top: 25px"
                      v-show="false"
                      >Leggi di più</a
                    >
                    <p
                      class="metadata__more"
                      style="width: 90%"
                      v-show="item.showMoreMetadata && false"
                    >
                      TODO: questa rivista quadrimestrale affronta ed
                      approfondisce argomenti che si presentano determinanti
                      nella vita delle persone: dalla gestione
                    </p>
                  </div>
                  <div class="element__caption__metadata">
                    <div
                    class="element__caption__add"
                    v-if="
                      showFavBtn &&
                      item._source.utente.acquistato &&
                      !item._source.utente.preferito
                    "
                  >
                    <span
                      class="label"
                      v-show="
                        settings.catalogo &&
                        settings.catalogo.filters &&
                        !settings.catalogo.filters.fromRiviste
                      "
                      >Già incluso in
                      <span class="title uppercase">Le Mie Riviste</span></span
                    >
                    <a
                      class=""
                      title="Aggiungi ai preferiti"
                      @click="addToFavorites(item)"
                    >
                      <span class="lnk-icon add"></span>
                      <div class="cta red small">Aggiungi ai preferiti</div>
                    </a>
                  </div>
                  <div
                    class="element__caption__add"
                    v-else-if="showFavBtn && item._source.utente.acquistato"
                  >
                    <span
                      class="label"
                      v-show="!settings.catalogo.filters.fromRiviste"
                      >Già incluso in
                      <span class="title uppercase">Le Mie Riviste</span></span
                    >
                    <a
                      class=""
                      title="Rimuovi dai preferiti"
                      @click="removeFromFavorites(item)"
                    >
                      <span class="lnk-icon remuve"></span>
                      <div class="cta red small">Rimuovi dai preferiti</div>
                    </a>
                  </div>
                    <div class="metadata-row">
                      <span class="metadata">Anno:</span>
                      <span class="metadata__value">{{
                        item._source.provvedimento.annoRiv
                      }}</span>
                    </div>
                    <div class="metadata-row">
                      <span class="metadata">Fascicolo:</span>
                      <span class="metadata__value"
                        >{{ item._source.provvedimento.fascicolo1 }}
                        {{ item._source.provvedimento.fascicolo2 ? " - " + item._source.provvedimento.fascicolo2 : "" }} </span
                      >
                    </div>
                    <div class="metadata-row" style="display: none">
                      <span class="metadata">Pagine fascicolo:</span>
                      <span class="metadata__value">194</span>
                    </div>
                    <div
                      class="metadata-row"
                      v-if="
                        item.highlight && !item._source.provvedimento.documenti
                      "
                    >
                      <div v-if="item.highlight['autori.cognome']">
                        <span
                          class="author"
                          style="
                            margin-top: 0px;
                            display: contents;
                            margin-left: 5px;
                          "
                          v-for="author in item.highlight['autori.cognome']"
                          :key="author"
                        >
                          <abbr v-html="author">
                            
                          </abbr>
                        </span>
                      </div>
                      <div v-if="item.highlight['testo.italian_stemmer']">
                      <span
                        class="abstract"
                        v-for="text in item.highlight['testo.italian_stemmer'].slice(0,2)"
                        :key="text"
                      >
                        <span v-html="text" style="text-transform:initial"></span>
                      </span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="box_expand">
                        <div class="other-results__box">
                          <div class="other-results__items" v-if="item._source.provvedimento
                                .documenti">
                            <div
                              class="other-results__items__item"
                              v-for="doc in item._source.provvedimento
                                .documenti.slice(0,1)"
                              :key="doc"
                            >
                              <div class="border-red">
                                <span
                                  class="part"
                                  v-if="doc._source.documento.rubrica"
                                  >{{ doc._source.documento.rubrica }}</span
                                >
                              </div>
                              <div class="box">
                                <div class="son">
                                  <v-tooltip right v-if="!showFavBtn">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div
                                        class="lock"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <!-- tooltip lucchetto -->
                                      </div>
                                    </template>
                                    <span
                                      >Per leggere questo contenuto è necessario
                                      acquistarlo</span
                                    >
                                  </v-tooltip>

                                  <div>
                                    <span
                                      class="author"
                                      style="
                                        margin-top: 0px;
                                        display: contents;
                                        margin-left: 5px;
                                      "
                                      v-for="(author, index) in doc._source
                                        .autori"
                                      :key="author"
                                    >
                                      <abbr
                                        v-if="author.nome && author.cognome"
                                        style="margin-right: 5px"
                                        >{{ author.nome }} {{ author.cognome
                                        }}{{ semicolon(doc, index) }} 
                                      </abbr>
                                      <span v-if="doc._source.documento.metaTitolo">| {{doc._source.documento.metaTitolo}}</span>
                                    </span>
                                    <span
                                      v-if="doc.metatitolo"
                                      class="author"
                                      style="
                                        margin-top: 0px;
                                        font-weight: normal;
                                        display: inline;
                                        margin-left: 0px;
                                      "
                                    >
                                      | {{ doc.metatitolo }}</span
                                    >
                                  </div>
                                  <h4
                                    @click="visite(doc, 'set', true); openViewer(doc)"
                                    :class="[
                                        {
                                          'title': true,
                                          'title-visited': visite(doc, 'check', true),
                                        },
                                      ]"
                                    v-show="false"
                                    v-if="
                                      doc._source &&
                                      doc._source.documento &&
                                      doc._source.documento.descrizione
                                    "
                                  >
                                    {{ doc._source.documento.descrizione }}
                                  </h4>
                                  <h4
                                    
                                    @click="visite(doc, 'set', true); openViewer(doc)"
                                    :class="[
                                        {
                                          'title': true,
                                          'title-visited': visite(doc, 'check', true),
                                        },
                                      ]"
                                    v-if="
                                      doc.highlight &&
                                      doc.highlight['documento.descrizione']
                                    "
                                    v-html="
                                      doc.highlight['documento.descrizione'][0]
                                    "
                                  ></h4>
                                  <div
                                  
                                    @click="visite(doc, 'set', true); openViewer(doc)"
                                    :class="[
                                        {
                                          'metadata-row': true,
                                          'title-visited': visite(doc, 'check', true),
                                        },
                                      ]"
                                    v-else-if="
                                      doc._source &&
                                      doc._source.documento &&
                                      doc._source.documento.descrizione
                                    "
                                  >
                                    <h4
                                      @click="visite(doc, 'set', true); openViewer(doc)"
                                      :class="[
                                        {
                                          'title': true,
                                          'title-visited': visite(doc, 'check', true),
                                        },
                                      ]"
                                      v-html="doc._source.documento.descrizione"
                                    ></h4>
                                  </div>
                                  <div
                                    v-if="
                                      item.highlight &&
                                      item.highlight['testo.italian_stemmer']
                                    "
                                  >
                                    <span
                                      class="abstract"
                                      v-for="text in item.highlight[
                                        'testo.italian_stemmer'
                                      ].slice(0,2)"
                                      :key="text"
                                    >
                                      <span v-html="text" style="text-transform:initial"></span>
                                    </span>
                                  </div>
                                  <span
                                    class="metadata"
                                    style="display: block !important; margin-top: 10px; !important"
                                    v-if="
                                      doc._source.documento.pagFine &&
                                      doc._source.documento.pagInizio
                                    "
                                  >
                                    Pagine:
                                    {{ fixPages(doc._source.documento) }}</span
                                  >
                                  <a
                                    class="lnk red metadata__lnkmore"
                                    @click="
                                      doc.showAbstractMetadata = !doc.showAbstractMetadata;
                                      $forceUpdate();
                                    "
                                    target="_blank"
                                    v-if="
                                      doc._source.documento.absIta ||
                                      doc._source.documento.absEng
                                    "
                                    >Abstract</a
                                  >
                                  <div
                                    class="metadata__more"
                                    v-show="doc.showAbstractMetadata"
                                  >
                                    <h6 v-if="doc._source.documento.absIta">
                                      Ita
                                    </h6>
                                    <p v-if="doc._source.documento.absIta" style="text-transform:initial">
                                      {{ doc._source.documento.absIta }}
                                    </p>
                                    <h6 v-if="doc._source.documento.absEng">
                                      Eng
                                    </h6>
                                    <p v-if="doc._source.documento.absEng" style="text-transform:initial">
                                      {{ doc._source.documento.absEng }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="other-results__items__item"
                              v-show="item.moreResults"
                              v-for="doc in item._source.provvedimento
                                .documenti.slice(1, 8)"
                              :key="doc"
                            >
                              <div class="border-red">
                                <span
                                  class="part"
                                  v-if="doc._source.documento.rubrica"
                                  >{{ doc._source.documento.rubrica }}</span
                                >
                              </div>
                              <div class="box">
                                <div class="son">
                                  <v-tooltip right v-if="!showFavBtn">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div
                                        class="lock"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <!-- tooltip lucchetto -->
                                      </div>
                                    </template>
                                    <span
                                      >Per leggere questo contenuto è necessario
                                      acquistarlo</span
                                    >
                                  </v-tooltip>

                                  <div>
                                    <span
                                      class="author"
                                      style="
                                        margin-top: 0px;
                                        display: contents;
                                        margin-left: 5px;
                                      "
                                      v-for="(author, index) in doc._source
                                        .autori"
                                      :key="author"
                                    >
                                      <abbr
                                        v-if="author.nome && author.cognome"
                                        style="margin-right: 5px"
                                        >{{ author.nome }} {{ author.cognome
                                        }}{{ semicolon(doc, index) }}</abbr
                                      >
                                      <span v-if="doc._source.documento.metaTitolo">| {{doc._source.documento.metaTitolo}}</span>
                                    </span>
                                    <span
                                      v-if="doc.metatitolo"
                                      class="author"
                                      style="
                                        margin-top: 0px;
                                        font-weight: normal;
                                        display: inline;
                                        margin-left: 0px;
                                      "
                                    >
                                      | {{ doc.metatitolo }}</span
                                    >
                                  </div>
                                  <h4
                                    @click="visite(doc, 'set', true); openViewer(doc)"
                                    :class="[
                                        {
                                          'title': true,
                                          'title-visited': visite(doc, 'check', true),
                                        },
                                      ]"
                                    v-show="false"
                                    v-if="
                                      doc._source &&
                                      doc._source.documento &&
                                      doc._source.documento.descrizione
                                    "
                                  >
                                    {{ doc._source.documento.descrizione }}
                                  </h4>
                                  <h4
                                    
                                    @click="visite(doc, 'set', true); openViewer(doc)"
                                    :class="[
                                        {
                                          'title': true,
                                          'title-visited': visite(doc, 'check', true),
                                        },
                                      ]"
                                    v-if="
                                      doc.highlight &&
                                      doc.highlight['documento.descrizione']
                                    "
                                    v-html="
                                      doc.highlight['documento.descrizione'][0]
                                    "
                                  ></h4>
                                  <div
                                    
                                    @click="visite(doc, 'set', true); openViewer(doc)"
                                    :class="[
                                        {
                                          'metadata-row': true,
                                          'title-visited': visite(doc, 'check', true),
                                        },
                                      ]"
                                    v-else-if="
                                      doc._source &&
                                      doc._source.documento &&
                                      doc._source.documento.descrizione
                                    "
                                  >
                                    <h4
                                      
                                      @click="visite(doc, 'set', true); openViewer(doc)"
                                      :class="[
                                        {
                                          'title': true,
                                          'title-visited': visite(doc, 'check', true),
                                        },
                                      ]"
                                      v-html="doc._source.documento.descrizione"
                                    ></h4>
                                  </div>
                                  <div
                                    v-if="
                                      doc.highlight &&
                                      doc.highlight['testo.italian_stemmer']
                                    "
                                  >
                                    <span
                                      class="abstract"
                                      v-for="text in doc.highlight[
                                        'testo.italian_stemmer'
                                      ].slice(0,2)"
                                      :key="text"
                                    >
                                      <span v-html="text" style="text-transform:initial"></span>
                                    </span>
                                  </div>
                                  <span
                                    class="metadata"
                                    style="display: block !important; margin-top: 10px; !important"
                                    v-if="
                                      doc._source.documento.pagFine &&
                                      doc._source.documento.pagInizio
                                    "
                                  >
                                    Pagine:
                                    {{ fixPages(doc._source.documento) }}</span
                                  >
                                  <a
                                    class="lnk red metadata__lnkmore"
                                    @click="
                                      doc.showAbstractMetadata = !doc.showAbstractMetadata;
                                      $forceUpdate();
                                    "
                                    target="_blank"
                                    v-if="
                                      doc._source.documento.absIta ||
                                      doc._source.documento.absEng
                                    "
                                    >Abstract</a
                                  >
                                  <div
                                    class="metadata__more"
                                    v-show="doc.showAbstractMetadata"
                                  >
                                    <h6 v-if="doc._source.documento.absIta">
                                      Ita
                                    </h6>
                                    <p v-if="doc._source.documento.absIta" style="text-transform:initial">
                                      {{ doc._source.documento.absIta }}
                                    </p>
                                    <h6 v-if="doc._source.documento.absEng">
                                      Eng
                                    </h6>
                                    <p v-if="doc._source.documento.absEng" style="text-transform:initial">
                                      {{ doc._source.documento.absEng }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div
                    class="element__caption__expand"
                    v-if="item._source.provvedimento.tipologiaDoc == 12 && 
                    item._source.provvedimento.documenti &&
                    item._source.provvedimento.documenti.length > 1"
                  >
                    <a
                      class="lnk red metadata__lnkmore"
                      v-show="
                        item._source.provvedimento.documenti &&
                        item._source.provvedimento.documenti.length > 1 &&
                        (item.moreResults == false || item.moreResults == null)
                      "
                      @click="expandFascicoli(true, item)"
                      >Espandi tutti i risultati</a
                    >
                    <a
                      class="lnk red metadata__lnkmore arrow-up"
                      v-show="item.moreResults == true"
                      @click="
                        item.moreResults = false;
                        $forceUpdate();
                      "
                      >Restringi tutti i risultati</a
                    >
                  </div>
                </div>
              </div>
              <div class="box_expand" v-if="false && showExpand && item.moreResults">
                <div class="other-results__box">
                  <div class="other-results__items">
                    <div
                      class="other-results__items__item"
                      v-for="doc in item._source.provvedimento.documenti"
                      :key="doc"
                    >
                      <div class="border-red">
                        <span
                          class="part"
                          v-if="doc._source.documento.rubrica"
                          >{{ doc._source.documento.rubrica }}</span
                        >
                      </div>
                      <div class="box">
                        <div class="son">
                          <v-tooltip right v-if="!showFavBtn">
                            <template v-slot:activator="{ on, attrs }">
                              <div class="lock" v-bind="attrs" v-on="on">
                                <!-- tooltip lucchetto -->
                              </div>
                            </template>
                            <span
                              >Per leggere questo contenuto è necessario
                              acquistarlo</span
                            >
                          </v-tooltip>

                          <div>
                            <span
                              class="author"
                              style="
                                margin-top: 0px;
                                display: contents;
                                margin-left: 5px;
                              "
                              v-for="(author, index) in doc._source.autori"
                              :key="author"
                            >
                              <abbr
                                v-if="author.nome && author.cognome"
                                style="margin-right: 5px"
                                >{{ author.nome }} {{ author.cognome
                                }}{{ semicolon(doc, index) }}</abbr
                              >
                              <span v-if="doc._source.documento.metaTitolo">| {{doc._source.documento.metaTitolo}}</span>
                            </span>
                            <span
                              v-if="doc.metatitolo"
                              class="author"
                              style="
                                margin-top: 0px;
                                font-weight: normal;
                                display: inline;
                                margin-left: 0px;
                              "
                            >
                              | {{ doc.metatitolo }}</span
                            >
                          </div>
                          <h4
                            class="title"
                            @click="openViewer(doc)"
                            v-show="false"
                            v-if="
                              doc._source &&
                              doc._source.documento &&
                              doc._source.documento.descrizione
                            "
                          >
                            {{ doc._source.documento.descrizione }}
                          </h4>
                          <h4
                            class="title"
                            @click="openViewer(doc)"
                            v-if="
                              doc.highlight &&
                              doc.highlight['documento.descrizione']
                            "
                            v-html="doc.highlight['documento.descrizione']"
                          ></h4>

                          <span
                            class="metadata"
                            style="display: block !important; margin-top: 10px; !important"
                            v-if="
                              doc._source.documento.pagFine &&
                              doc._source.documento.pagInizio
                            "
                          >
                            Pagine: {{ fixPages(doc._source.documento) }}</span
                          >
                          <a
                            class="lnk red metadata__lnkmore"
                            @click="
                              doc.showAbstractMetadata = !doc.showAbstractMetadata;
                              $forceUpdate();
                            "
                            target="_blank"
                            v-if="
                              doc._source.documento.absIta ||
                              doc._source.documento.absEng
                            "
                            >Abstract</a
                          >
                          <div
                            class="metadata__more"
                            v-show="doc.showAbstractMetadata"
                          >
                            <h6 v-if="doc._source.documento.absIta">Ita</h6>
                            <p v-if="doc._source.documento.absIta" style="text-transform:initial">
                              {{ doc._source.documento.absIta }}
                            </p>
                            <h6 v-if="doc._source.documento.absEng">Eng</h6>
                            <p v-if="doc._source.documento.absEng" style="text-transform:initial">
                              {{ doc._source.documento.absEng }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="results__list__pagination clearfix"
          v-if="this.settings.catalogo && this.settings.catalogo.search.es.total > 0"
        >
          <div class="col50 floatL">
            <span class="results-total">
              <span class="number">{{
                this.settings.catalogo.search.es.total
              }}</span
              ><span>risultati trovati</span>
            </span>
          </div>
          <div class="col50 floatR">
            <div class="pagination-number">
              <div class="pagination-number">
                <v-pagination
                  v-model="page"
                  v-if="totalResults > 8"
                  :length="totalPages"
                  :total-visible="8"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- fine MAIN -->
  </div>
</template>

<script>
import axios from "axios";
import configuration from "../configuration_variables.json";
import viewer from "../components/Viewer";

export default {
  props: {
    authorization: {
      type: Object,
    },
    overlay: {
      type: Boolean,
    },
    settings: {
      type: Object,
    },
  },
  components: {
    Viewer: viewer,
  },
  data: (vm) => ({
    page: 1,
    totalPages: 1,
    loaded: false,
    dateFrom: null,
    dateTo: null,
    author: "",
    user: null,
    catalogue: null,
    materie: [],
    chapters: [],
    materiaSelected: null,
    totalResults: 0,
    currentAuthor: "",
    showExpand: false,
    paginationKey: "page",
    searchText: null,
    removenlp: false,
    fav: false,
    currentCodRiv: null,
    chapterText: "",
    errorMin: false,
    errorMax: false,
    filter_onriviste: null,
    allWordsSelect: [
      "Tutte le parole",
      "Frase esatta"
    ],
    filter_onwords: "Tutte le parole",
    filtersTypeResearch: ["Tutto il Catalogo", "Le mie riviste"],
    rules: {
      min: (value) => vm.check(value, true),
      max: (value) => vm.check(value, false),
    },
    typeResearch: "Tutto il Catalogo",
    qsParamSearch: "",
    list: [],
    showResAdv: false,
    nlp: null,
    menu: false,
    message: false,
    hints: true,
    rivisteQuery: false,
    showMoreMetadata: false,
    loadingAdd: false,
    showUnlimitedBtn: false,
    showViewer: false,
    showUnlimitedBtnDelete: true,
    showMoreAuthors: false,
    appendIconData: "arrow_drop_down",
    currentKey: "viewer",
    loading_viewer: false,
    showFavBtn: false,
    rivisteSelect: [],
    last2Years: false,
    last5Years: false,
    placeholder: "Cerca in tutto il Catalogo GFL",
    currentDateFiltering: "",
    currentOrder: {
      id: 1,
      name: "Più pertinente",
      sort: {
        name: "_score",
        order: "desc",
      },
    },
    orders: [
      {
        id: 1,
        name: "Più pertinente",
        sort: {
          name: "_score",
          order: "desc",
        },
      },
      {
        id: 2,
        name: "Più recente",
        sort: {
          name: "provvedimento.data",
          order: "desc",
        },
      },
    ],
  }),
  watch: {
    page(val) {
      this.showResAdv = true;
      //this.dateFrom = this.settings.catalogo.filters ? this.settings.catalogo.filters.dateFrom : null;
      //this.dateTo = this.settings.catalogo.filters ? this.settings.catalogo.filters.dateTo : null;
      // && !this.settings.catalogo.comingBack
      if((this.settings.catalogo) || this.$route.query.fromButton == "true" && this.$route.query.riviste == "true"){
      this.research((val - 1) * 8, 8, this.typeResearch);
      }
      else{
        //this.settings.catalogo.comingBack = false;
      }
      window.scrollTo(0, 0);
    },
    filter_onriviste(val) {
      if (this.settings.rivisteSelect) {
        var filter = this.settings.rivisteSelect.find(
          (x) => x.descrizione == val
        );
        this.currentRivistaSelectedId = filter.cod_riv;
      } else {
        this.currentRivistaSelectedId = this.settings.currentRivistaSelectedId.cod_riv;
      }
    },
    menu(val) {
      /*
      @click="changeIcon()"
      @blur="changeIcon()"
      onfocusout="changeIcon()"
      */
      if (val == false) this.appendIconData = "arrow_drop_down";
      else this.appendIconData = "arrow_drop_up";
    },
  },
  mounted() {
    //this.$gtm.trackView("pageview", this.$route.path);
    this.$emit("checkQSTicket", this.$route.query); //va messo in tutte le viste
  localStorage.magazineCall = false;
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt,
        };

    if (this.authorization.jwt) {
      this.user = this.authorization;
      var logged = this.user.profile.roles.find((x) => x == "pay")
        ? true
        : false;
      if (logged) {
        this.showFavBtn = true;
        //successivamente verifichiamo se acquistato = true
      } else {
        this.showFavBtn = false;
      }
    }
    if (this.$route.query.fav == "true") {
      this.fav = true;
    }
    if (this.$route.query.riviste == "true") {
      this.rivisteQuery = true;
      this.settings.curentFilter = "Le mie riviste";
      this.typeResearch = this.settings.curentFilter;
    }

    this.checkRules();
    
    if (this.settings.catalogo && this.settings.catalogo.search) {
      this.filter_onriviste = this.settings.currentRivistaSelectedId
        ? this.settings.currentRivistaSelectedId.descrizione
        : null;
      this.currentRivistaSelectedId = this.filter_onriviste
        ? this.filter_onriviste.cod_riv
        : null;
      this.searchText = this.settings.currentSearchWord;
      if (this.searchText) this.showExpand = true;
      this.materie = JSON.parse(localStorage.materie);
      this.materiaSelected = this.settings.catalogo.fromDetail
        ? null
        : this.settings.catalogo.materiaSelected;
      this.list = this.settings.catalogo.search.es.list
        ? this.settings.catalogo.search.es.list
        : [];

      this.typeResearch = this.settings.curentFilter
        ? this.settings.curentFilter
        : "Tutto il Catalogo";
      if (
        this.settings.catalogo.search.nlp &&
        this.settings.catalogo.search.nlp.entities &&
        this.settings.catalogo.search.nlp.entities.length > 0
      ) {
        var autore = this.settings.catalogo.search.nlp.entities.find(
          (x) => x.entity.toString() == "autore"
        );
        if (autore) {
          this.showSearchByAuthor = true;
          this.currentAuthor = autore.value;
        }
      } else {
        this.showSearchByAuthor = false;
      }
      if (this.settings.catalogo.filters) {
        if(this.settings.catalogo.filters.qsParamSearch){
           this.qsParamSearch = "&query_type=phrase";
           this.filter_onwords = "Frase esatta";
        }

        this.currentCodRiv = this.settings.catalogo.filters.codRiv;
        if (this.settings.comingBack) {
          this.last2Years = this.settings.catalogo.filters.last2Years
            ? this.settings.catalogo.filters.last2Years
            : null;
          this.last5Years = this.settings.catalogo.filters.last5Years
            ? this.settings.catalogo.filters.last5Years
            : null;
          //this.settings.comingBack = false;
        }

        this.dateFrom = this.settings.catalogo.filters.dateFrom
          ? parseInt(this.settings.catalogo.filters.dateFrom)
          : null;
        this.dateTo = this.settings.catalogo.filters.dateTo
          ? parseInt(this.settings.catalogo.filters.dateTo)
          : null;
        //this.showResAdv = true;
        this.currentDateFiltering = this.dateFrom
          ? ": " + this.dateFrom + " - " + this.dateTo
          : "";

        this.author = this.settings.catalogo.filters.author
          ? this.settings.catalogo.filters.author
          : null;
        /*this.typeResearch = this.settings.catalogo.filters.typeResearch
          ? this.settings.catalogo.filters.typeResearch
          : "Tutto il Catalogo";*/
        this.page = this.settings.catalogo.filters.currentPage
          ? this.settings.catalogo.filters.currentPage
          : 1;
      }

      if (this.typeResearch == "Tutto il Catalogo")
        this.placeholder = "Cerca in tutto il Catalogo GFL";
      if (this.typeResearch == "I miei volumi")
        this.placeholder = "Cerca ne I miei Volumi";
      if (this.typeResearch == "Unlimited")
        this.placeholder = "Cerca in Unlimited";

      var headerFreeJwt = {
          Authorization: this.settings.free.jwt, //tutto il catalogo, free
      };

      this.REQUEST(
        this.settings.configuration.apiURL + "/riviste/form/cb",
        this.typeResearch == "Tutto il Catalogo" ? headerFreeJwt : header,
        "GET",
        "comboRiviste",
        null
      );

      this.totalResults = this.settings.catalogo.search.es.total
        ? this.settings.catalogo.search.es.total
        : 0;
      this.nlp = this.settings.catalogo.search.nlp;
      this.totalPages =
        parseInt(Math.round(this.totalResults / 8)) >= 1
          ? parseInt(Math.ceil(this.totalResults / 8))
          : 1;
      this.chapterText = this.settings.catalogo.search.nlp
        ? this.settings.catalogo.search.nlp.textOrig
        : null;
      this.catalogue = true;
      this.$forceUpdate();
    } else {
      if (this.settings.configuration) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt,
        };

        if (localStorage.materie) {
          //controllo la data di scadenza per il refresh, settata a 20 min
          if (localStorage.dateSavedMaterie) {
            var now = Date.parse(new Date()) / 1000;
            var exp =
              parseInt(localStorage.dateSavedMaterie) +
              parseInt(this.settings.configuration.timeoutReloadMaterie);
            if (now >= exp) {
              this.loadMaterie(header);
            } else {
              this.materie = JSON.parse(localStorage.materie);
            }
          } else {
            localStorage.materie = [];
            this.loadMaterie(header);
          }
        } else {
          this.loadMaterie(header);
        }

          var headerFreeJwt = {
            Authorization: this.settings.free.jwt, //tutto il catalogo, free
        };

        this.REQUEST(
          this.settings.configuration.apiURL + "/riviste/form/cb",
          this.typeResearch == "Tutto il Catalogo" ? headerFreeJwt : header,
          "GET",
          "comboRiviste",
          null
        );

        if (
          this.$route.query.more == "true" ||
          this.$route.query.more == true
        ) {
          this.searchText = "";
          this.currentOrder = {
            id: 2,
            name: "Più recente",
            sort: {
              name: "provvedimento.data",
              order: "desc",
            },
          };
          if (this.settings.comingBack) {
            this.settings.comingBack = false;
            this.page = this.settings.catalogo.currentPage;
          } else {
            this.page = 2;
          }
          //this.research((this.page - 1) * 8, 8, "Tutto il Catalogo");
          this.catalogue = true;
        } else {
          this.$router.push({ path: "/" });
        }
      }
    }
  },
  created() {
    this.$gtm.trackEvent({
      target: "home",
      action: "pageview",
      path: this.$route.path,
      crmId:
        this.authorization &&
        this.authorization.profile &&
        this.authorization.profile.crmId
          ? this.authorization.profile.crmId
          : "free",
      roles:
        this.authorization &&
        this.authorization.profile &&
        this.authorization.profile.roless
          ? this.authorization.profile.roless
          : null,
      session: this.createSessionTimestamp(),
    });
  },
  methods: {
    async REQUEST(call, header, method, type, body, item) {
      var self = this;
      this.loading = true;
      if (method == "POST") {
        await axios
          .post(call, body, { headers: header })
          .then(function (response) {
            switch (type) {
              case "list":
                self.catalogue = response.data.list;
                break;
              case "addToFav":
                self.showResAdv = true;
                self.research(
                  (self.page - 1) * 8,
                  8,
                  self.typeResearch,
                  self.searchText
                );
                break;
              case "chapters":
                self.chapters = response.data.list;

                break;
              case "expand":
                item.documenti = response.data.es.list;
                self.$forceUpdate();
                break;
              case "unlimited":
                self.loadingAdd = false;
                self.list.forEach((element) => {
                  if (body.cod_vol == element._source.provvedimento.codVol) {
                    element.showUnlimitedBtnDelete = true;
                    element.showUnlimitedBtn = false;
                  }
                });
                self.$forceUpdate();
                break;
              case "search":
                self.settings.currentSearchWord = self.searchText
                  ? self.searchText
                  : null;
                self.showExpand = !self.searchText ? false : true;
                self.list = response.data.es.list;
                self.list = self.list ? self.list : [];

                self.nlp = response.data.nlp;
                self.totalResults = response.data.es.total
                  ? response.data.es.total
                  : 0;
                self.totalPages =
                  parseInt(Math.round(self.totalResults / 8)) >= 1
                    ? parseInt(Math.ceil(self.totalResults / 8))
                    : 1;

                if (
                  (self.$route.query.more == "true" ||
                    self.$route.query.more == true) &&
                  (self.$route.query.fromButton == "true" ||
                    self.$route.query.fromButton == true) &&
                  self.$route.query.fav == "false"
                ) {
                  self.totalPages = 3; // configuare page x 3 costante
                }
                self.chapterText = self.nlp ? response.data.nlp.textOrig : null;
                self.loaded = true;
                if(self.settings.catalogo){
                self.settings.catalogo = {
                  search: response.data,
                  materiaSelected: self.materiaSelected,
                  materie: self.materie,
                  fromDetail: false,
                  filters: {
                    codRiv: self.currentCodRiv,
                    dateFrom: self.dateFrom,
                    dateTo: self.dateTo,
                    author: self.author,
                    typeResearch: self.typeResearch,
                    currentPage: self.page,
                    qsParamSearch: self.qsParamSearch,
                    fromRiviste: (self.settings.catalogo.filters.typeResearch == 'Le mie riviste' || self.settings.curentFilter == 'Le mie riviste')
                    ? true : false
                  }
                };
                }
                else{
                  self.settings.catalogo = {
                  search: response.data,
                  materiaSelected: self.materiaSelected,
                  materie: self.materie,
                  fromDetail: false,
                  filters: {
                    codRiv: self.currentCodRiv,
                    dateFrom: self.dateFrom,
                    dateTo: self.dateTo,
                    author: self.author,
                    typeResearch: self.typeResearch,
                    currentPage: self.page,
                    fromRiviste: (self.$route.query.riviste == "true") ? true : false
                  }
                };
                }
                if (
                  response.data.nlp &&
                  response.data.nlp.entities.length > 0
                ) {
                  var autore = response.data.nlp.entities.find(
                    (x) => x.entity.toString() == "autore"
                  );
                  if (autore) {
                    self.showSearchByAuthor = true;
                    self.currentAuthor = autore.value;
                  }
                } else {
                  self.showSearchByAuthor = false;
                  self.settings.catalogo.search.nlp = {};
                  self.settings.catalogo.search.nlp.textOrig = self.searchText;
                }
                /*self.showResAdv = false;*/
                self.$forceUpdate();
              //self.refreshComponent();
            }
          })
          .catch(function (error) {
            switch (type) {
              case "addToFav":
                Swal.fire({
                  icon: "error",
                  title: "",
                  text: error.response.data.message,
                });
                break;
            }
            //localStorage.token_biblio_riv = null;
            //localStorage.token_biblio_riv_jwt = self.settings.free.jwt;
            //self.token = self.settings.free.jwt;
            //self.settings.freeToken = true;
          });
      } else if (method == "GET") {
        await axios
          .get(call, { headers: header })
          .then(function (response) {
            switch (type) {
              case "materie":
                self.materie = response.data;
                self.materie.unshift({
                  descrizione: "Tutto il catalogo".toUpperCase(),
                });
                self.settings.catalogo.materie = self.materie;
                localStorage.materie = JSON.stringify(self.materie);
                localStorage.dateSavedMaterie = Date.parse(new Date()) / 1000;
                self.$forceUpdate();
                break;
              case "comboRiviste":
                self.rivisteSelect = response.data.riviste;
                self.settings.rivisteSelect = self.rivisteSelect;
                self.$forceUpdate();
                break;
              case "recoverSummaryApart":
                var cod = call.split("sommario/")[1];
                var elements = response.data.documenti;
                self.list.forEach((element) => {
                  if (
                    element._source.provvedimento.idDocMaster == parseInt(cod)
                  ) {
                    element.indexList = elements;
                    self.$forceUpdate();
                  }
                });
                break;
              case "detail":
                var cod = call.split("codice_volume/")[1];
                var item = response.data;
                if (!item.documenti || self.settings.freeToken) {
                  //non sono loggato, recupero il sommario a parte
                  //nascondo certi elementi
                  self.recoverSummary(item.provvedimento.id_doc_master);
                } else {
                  self.list.forEach((element) => {
                    if (element._source.provvedimento.codVol == cod) {
                      element.indexList = item.documenti;
                      self.$forceUpdate();
                    }
                  });
                }
                break;
            }
          })
          .catch(function (error) {
            self.loaded = true;
          });
      } else {
        await axios
          .delete(call, { data: body, headers: header })
          .then(function (response) {
            switch (type) {
              case "remFromFav":
                self.showResAdv = true;
                self.research(
                  (self.page - 1) * 8,
                  8,
                  self.typeResearch,
                  self.searchText
                );
                break;
            }
          })
          .catch(function (error) {

          });
      }
    },
    fixPages(doc) {
      /*if(doc.pag_fine && doc.pag_inizio) return parseInt(doc.pag_fine) - parseInt(doc.pag_inizio);
      else{
        console.log("Al doc: " + doc.id_unita_doc + " manca pagina x calcolo");
        if(doc.pag_fine) return doc.pag_fine;
        else return doc.pag_inizio;
      }*/
      if (doc.pagFine && doc.pagInizio) {
        return "da " + doc.pagInizio + " a " + doc.pagFine;
      } else {
        //console.log("Al doc: " + doc.idUnitaDoc + " manca pagina x calcolo");
        if (doc.pagFine) return doc.pagFine;
        else return doc.pagInizio;
      }
    },
    createSessionTimestamp() {
      var d = new Date();
      var session =
        d.getFullYear().toString() +
        d.getMonth().toString() +
        d.getDate().toString() +
        d.getHours() +
        d.getMinutes() +
        d.getSeconds();
      return session;
    },
    getChapters(item) {
      var header = {
        Authorization: localStorage.token_biblio_riv_jwt,
      };
      var body = {
        text: this.searchText,
        sort: {
          name: "_score",
          order: "desc",
        },
        filters: [
          {
            name: "codVol",
            value: item._source.provvedimento.codVol,
          },
        ],
      };
      this.REQUEST(
        configuration.apiURL + "/ricerca/riviste/capitoli/0/100",
        header,
        "POST",
        "chapters",
        body
      );
    },
    openViewer(item) {
      this.loading_viewer = true;
      var url = this.settings.pdfviewer ? this.settings.pdfviewer + "newViewer.html" : this.settings.pdfviewer_express;
      if (this.hasEntities) {
        url += "?token=" + item.v_token;
      } else {
        if (true) {
          var _search = this.searchText ? this.searchText : this.author
          url += "?search=" + _search + 
          "&env=riviste" +
          "&token=" + item.v_token;
        } else {
          url += "?token=" + item.v_token +  "&env=riviste";
        }
      }
      //window.open(url, "_blank");
      this.settings.viewerUrl = url;
      this.showViewer = true;
      window.scroll(0, 0);
      //this.refreshComponent();
      setTimeout(() => {
        this.loading_viewer = false;
      }, 3000);
    },
    rndStr(len) {
      let text = " ";
      let chars = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return text;
    },
    showMoreAuth(item) {
      item.showMoreAuthors = true;
      this.$forceUpdate();
    },
    addToFavorites(item) {
      var header = {
        Authorization: localStorage.token_biblio_riv_jwt,
      };
      var body = {
        idDocMaster: item._source.provvedimento.idDocMaster,
      };
      this.REQUEST(
        configuration.apiURL + "/preferiti",
        header,
        "POST",
        "addToFav",
        body
      );
    },
    removeFromFavorites(item) {
      var header = {
        Authorization: localStorage.token_biblio_riv_jwt,
      };
      var body = {
        idDocMaster: item._source.provvedimento.idDocMaster,
      };
      this.REQUEST(
        configuration.apiURL + "/preferiti",
        header,
        "DELETE",
        "remFromFav",
        body
      );
    },
    fixCopertine(uri){
        return uri.replace(/([^:]\/)\/+/g, '$1')
      },
    refreshComponent() {
      this.currentKey = this.rndStr(8);
      this.paginationKey = this.rndStr(8);
      this.$forceUpdate();
    },
    check(val, from) {
      if (from) {
        if (parseInt(val) > parseInt(this.dateTo)) {
          return "Errore nelle date.";
        }
      } else {
        if (val != "" && parseInt(val) < parseInt(this.dateFrom))
          return "Errore nelle date.";
      }
      return ""
    },
    toggleIndex(item) {
      item.indexOpen = !item.indexOpen;
      if (item.indexOpen && item.indexList.length == 0) {
        //richiamo l'indice
        this.recoverIndex(item._source.provvedimento.codVol);
      } else {
      }
      this.$forceUpdate();
    },
    toggleChapters(item) {
      item.indexOpen = !item.indexOpen;
      if (item.indexOpen && item.indexList.length == 0) {
        //richiamo l'indice
        this.getChapters(item);
      } else {
      }
      this.$forceUpdate();
    },
    semicolon(doc, index) {
      return doc._source.autori.length > 1 &&
        index < doc._source.autori.length - 1
        ? ";"
        : "";
    },
    removeFromUnlimited(item) {
      var header = {
        Authorization: localStorage.token_biblio_riv_jwt,
      };
      var body = {
        cod_vol: item._source.provvedimento.codVol,
      };
      this.REQUEST(
        configuration.apiURL + "/unlimited",
        header,
        "DELETE",
        "unlimited",
        body
      );
    },
    expandFascicoli(first, item) {
      if (first && !item.documenti && false) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt,
        };
        var body = {
          text: this.searchText,
          sort: {
            name: "_score",
            order: "desc",
          },
          useCache: false,
        };

        this.REQUEST(
          configuration.apiURL +
            "/ricerca/fascicolo/" +
            item._source.provvedimento.idDocMaster +
            "/0/30?nlp=false&jwt_role=true",
          header,
          "POST",
          "expand",
          body,
          item
        );
      }
      item.moreResults = true;
      this.$forceUpdate();
    },

    resetFields() {
      this.dateFrom = null;
      this.dateTo = null;
      this.materiaSelected = null;
      this.filter_onriviste = null;
      //this.settings.catalogo ? (this.settings.catalogo.filters = null) : null;
      this.currentCodRiv = null;

      this.$router.push({
        path: "/riviste"
      });

      //
      //this.research((this.page - 1) * 8, 8, this.typeResearch);
    },
    addToUnlimited(item) {
      var header = {
        Authorization: localStorage.token_biblio_riv_jwt,
      };
      var body = {
        cod_vol: item._source.provvedimento.codVol,
      };
      this.REQUEST(
        configuration.apiURL + "/unlimited",
        header,
        "POST",
        "unlimited",
        body
      );
    },
    recoverIndex(codice) {
      var header = {
        Authorization: localStorage.token_biblio_riv_jwt,
      };
      this.REQUEST(
        configuration.apiURL + "/volume/info/codice_volume/" + codice,
        header,
        "GET",
        "detail",
        null
      );
    },
    fixcss(){
      document.getElementsByClassName("maxlayout")[0].style.maxWidth = "1240px"; 
    },
    visite(item, type, summary) {
      if (type == "set") {
        var id = summary ? item._id : item._source.provvedimento.codRiv;
        var arr = [];
        if (!localStorage.highlight_id) arr = [];
        else arr = JSON.parse(localStorage.highlight_id);

        arr.push(id.toString());
        localStorage.highlight_id = JSON.stringify(arr);
      } else if (type == "check" && localStorage.highlight_id) {
        var find = "";
        if (!summary) {
          find = JSON.parse(localStorage.highlight_id).find(
            (x) => x == item._source.provvedimento.codRiv.toString()
          );
        } else {
          find = JSON.parse(localStorage.highlight_id).find(
            (x) => x == item._id.toString()
          );
        }
        if (find) return true;
        else return false;
      } else return false;
    },
    recoverSummary(id) {
      var header = {
        Authorization: localStorage.token_biblio_riv_jwt,
      };
      this.REQUEST(
        configuration.apiURL + "/volume/sommario/" + id,
        header,
        "GET",
        "recoverSummaryApart",
        null
      );
    },
    loadMaterie(header) {
      this.REQUEST(
        this.settings.configuration.apiURL + "/materie",
        header,
        "GET",
        "materie",
        null
      );
    },
    setMateria() {
      this.page = 1;
      this.showResAdv = true;
      //this.research((this.page - 1) * 8, 8, this.typeResearch);
    },
    setOrder() {
      this.research((this.page - 1) * 8, 8, this.typeResearch);
    },
    verifyUserConditions(item, type) {
      //usa solo i flag show oppure solo questa condizione.. e poi verifica che funzioni lo stesso giro pure dentro details.vue
      //agg ad unlimited: se isUnlimited è true e utente.unlimited è false
      //remove from unlimited: se isUnlimited è true e utente.unlimited è true
      if (type == "unlimited") {
        if (this.settings.freeToken) {
          return false;
        } else {
          if (this.checkRulesUnlimited()) {
            if (
              item._source.provvedimento.utente.unlimited == false &&
              item._source.provvedimento.isUnlimited
            ) {
              return true;
              //return true;
            } else return false;
          } else return false;
        }
      } else if (type == "abbonato") {
        if (this.settings.freeToken) {
          return false;
        } else {
          if (this.checkRules()) {
            if (item._source.provvedimento.utente.volume) {
              return true;
            } else return false;
          } else return false;
        }
      } else if (type == "unlimitedDelete") {
        //
        if (this.settings.freeToken) {
          return false;
        } else {
          if (this.checkRulesUnlimited()) {
            if (
              item._source.provvedimento.utente.unlimited == true &&
              item._source.provvedimento.isUnlimited
            ) {
              return true;
              //return true;
            } else return false;
          } else return false;
        }
      }
    },
    generateCover(item) {
      if (
        item._source.provvedimento.campiCalcolati &&
        item._source.provvedimento.campiCalcolati.cover_url &&
        item._source.provvedimento.idDocMaster
      ) {
        return (
          this.settings.copertina +
          "/pdf/" +
          item._source.provvedimento.idDocMaster
            .toString()
            .substring(
              item._source.provvedimento.idDocMaster.toString().length - 3,
              item._source.provvedimento.idDocMaster.toString().length
            ) +
          item._source.provvedimento.campiCalcolati.cover_url +
          "?width=280"
        );
      } else if (
        item._source.provvedimento.campiCalcolati &&
        item._source.provvedimento.campiCalcolati.rivista_cover_url
      ) {
        return item._source.provvedimento.campiCalcolati.rivista_cover_url;
      } else {
        return this.settings.configuration.placeholderIMG;
      }
    },

    detail(item) {
      this.$router.push({
        path: "/details",
        query: {
          id_doc_master: item._source.provvedimento.idDocMaster,
          fromSearch: this.searchText,
          fromFilters: this.searchText ? false : true,
        },
      });
    },
    checkRules() {
      if (this.user) {
        var role = this.user.profile.roles.find((x) => x == "pay");
        if (role)
          this.filtersTypeResearch = ["Tutto il Catalogo", "Le mie riviste"];
        else this.filtersTypeResearch = ["Tutto il Catalogo"];
      } else {
        this.filtersTypeResearch = ["Tutto il Catalogo"];
      }
    },
    checkRulesNotUnlimited() {
      if (this.user) {
        var role37 = this.user.profile.roles.find(
          (x) => x.pos.toString().toLowerCase() == "37"
        );
        var role36 = this.user.profile.roles.find(
          (x) => x.pos.toString().toLowerCase() == "36"
        );
        var unl = this.user.profile.roles.find(
          (x) => x.pos.toString().toLowerCase() == "bvunlimited"
        );
        if (role37 && !unl) return true;
        //c'era in or anche !role36
        else return false;
      } else {
        return true;
      }
    },
    checkRulesUnlimited() {
      if (this.user) {
        var roleUnl = this.user.profile.roles.find(
          (x) => x.pos.toString().toLowerCase() == "bvunlimited"
        ); // || x.pos.toString() == "36"
        if (roleUnl) return true;
        else return false;
      } else return false;
    },
    cleanAll() {
      this.$route.query.more = null;
      this.page = 1;
      this.materiaSelected = null;
      this.showResAdv = false;
      this.currentDateFiltering = "";
      this.filter_onriviste = null;
      this.currentRivistaSelectedId = null;
      this.dateTo = null;
      this.dateFrom = null;
      this.last2Years = false;
      this.last5Years = false;
      this.fav = false;
      this.rivisteQuery = false;
    },
    keyPress(e) {
      if (e.keyCode === 13) {
        this.cleanAll();
        this.research((this.page - 1) * 8, 8, "Tutto il Catalogo", true);
      }
      if (this.searchText == "") this.settings.currentSearchWord = null;
    },
    filtering() {
      this.page = 1;
      this.settings.curentFilter = this.typeResearch;

      var headerFreeJwt = {
          Authorization: this.settings.free.jwt, //tutto il catalogo, free
      };

      var header = {
        Authorization: localStorage.token_biblio_riv_jwt,
      };

      this.REQUEST(
        this.settings.configuration.apiURL + "/riviste/form/cb",
        this.typeResearch == "Tutto il Catalogo" ? headerFreeJwt : header,
        "GET",
        "comboRiviste",
        null
      );

      //this.research((this.page - 1) * 8, 8, this.typeResearch);
    },
    customSearch(){
      this.fav = false;
      this.rivisteQuery = false;
      this.page == 1 ? this.research(0, 8, this.typeResearch, false) : this.page = 1;
    },
    research(from, to, type, newSearch) {
      var body = {};
      var stop = false;
      if (newSearch) {
        this.cleanAll();
      }
      if (true) {
        //tolta logica materia multiple, se va rimessa recuperala da result2
        if (
          this.materiaSelected &&
          this.materiaSelected.descrizione.toUpperCase() != "TUTTO IL CATALOGO"
        ) {
          body = {
            filters: [
              { name: "materia", value: [this.materiaSelected.id.toString()] },
            ],
            sort: {
              name: "_score",
              order: "desc",
            },
          };
        } else {
          body = {
            filters: [],
            sort: {
              name: "_score",
              order: "desc",
            },
          };
        }
        if (
          this.searchText ||
          this.$route.query.more == "true" ||
          this.$route.query.more == true
        ) {
          if (this.settings.currentSearchWord)
            body.text = this.settings.currentSearchWord;
          if (this.searchText) body.text = this.searchText;
          if (this.settings.currentSearchWord == null) delete body.text;
        }
        if (!this.searchText && !this.settings.currentSearchWord) {
          body.sort = {
            name: "cronologico",
            order: "desc",
          };
        }

        if (this.showResAdv) {
          var filterDate = {};
          //verifiche
          if (
            parseInt(this.dateFrom) <= parseInt(this.dateTo) &&
            parseInt(this.dateFrom) > 0 &&
            parseInt(this.dateTo) > 0
          ) {
            if (this.dateFrom != null || this.dateTo != null) {
              filterDate.name = "range";
              var values = {
                from: this.dateFrom ? parseInt(this.dateFrom) : 1930,
                to: this.dateTo ? parseInt(this.dateTo) : 2100,
              };
              filterDate.value = values;
              body.filters.push(filterDate);
            }
          } else {
            if (this.dateFrom && this.dateTo) stop = true;
          }
          var filterAuthor = {};
          if (this.author != "" && this.author != null) {
            filterAuthor.name = "autore";
            var values = {
              cognome: this.author,
            };
            filterAuthor.value = values;
            body.filters.push(filterAuthor);
          }
        }
        if (
          this.currentCodRiv ||
          (this.settings.catalogo &&
            this.settings.catalogo.filters &&
            this.settings.catalogo.filters.codRiv)
        ) {
          this.currentCodRiv = this.settings.catalogo.filters.codRiv;
          body.filters.push({
            name: "codRiv",
            value: this.settings.catalogo.filters.codRiv,
          });
        } else {
          this.currentCodRiv = null;
        }
        if (this.filter_onriviste && this.currentRivistaSelectedId) {
          var filter = this.rivisteSelect.find(
            (x) => x.descrizione == this.filter_onriviste
          );
          this.settings.currentRivistaSelectedId = filter;
          body.filters.push({
            name: "codRiv",
            value: this.currentRivistaSelectedId,
          });
        }
        if (this.fav) {
          body.filters.push({
            name: "codiciPreferiti",
            value: true,
          });
        }
        if (this.searchText) { //A.R
          body.detail = true;
        } else {
          body.detail = false;
        }
        if (!stop) {
          var header = {
            Authorization: localStorage.token_biblio_riv_jwt,
          };
          //var nlp = this.removenlp ? "?nlp=false" : "?nlp=true";
          var nlp = "?nlp=false";
          var jwt_role =
            type == "Le mie riviste" ? "jwt_role=true" : "jwt_role=false";
          if (this.rivisteQuery) {
            jwt_role = "jwt_role=true";
          }
          if(this.searchText && this.filter_onwords == "Frase esatta"){
            this.qsParamSearch = "&query_type=phrase";
          }
          else{
            this.qsParamSearch = "";
          }
          this.REQUEST(
            this.settings.configuration.apiURL +
              "/ricerca/riviste/" +
              from +
              "/" +
              to +
              nlp +
              "&" +
              jwt_role + this.qsParamSearch,
            header,
            "POST",
            "search",
            body
          );
        }
      }
    },
  },
};
</script>
<style>
.v-menu__content {
  box-shadow: 0 3px 12px #cfcfcf !important;
}

.v-select__selections input,
.srch input {
  background: transparent !important;
  border: none;
  padding-left: 0px !important;
}
.element__caption__add {
  /*position: relative !important;*/
}
.floatR .slct {
  border: solid 1px #e6e6e6;
}

.inp .v-input__slot {
  /*width: 100px !important;*/
}
.srch .v-input__slot {
  height: 40px !important;
  font-size: 11pt;
}

.v-menu__content.theme--light.menuable__content__active {
  /*min-width: 350px !important;
  width: 350px !important;*/
}

.srch input {
  /*cursor: pointer;*/
}
.title-visited {
  color: #A53535 !important;
}
em {
  /*background: gold !important;
  padding: 2px !important;*/
  color: #ba0000;
  font-size: inherit !important;
  font-weight: normal !important;
  font-style: initial !important;
}

.v-input__slot input::-webkit-outer-spin-button,
.v-input__slot input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.v-input__slot input[type="number"] {
  border: none;
}

@media (max-width: 767px) {
  .search__caption__copy__searchbox .srch,
  .search__caption__copy__searchbox .srch {
    margin-bottom: 0.7em;
    margin-left: 0px !important;
    width: 100% !important;
    border: none !important;
  }
  .srch {
    margin-top: 5px;
  }
  .floatR .slct {
    border: none;
  }
}
</style>